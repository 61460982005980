import { useState } from "react";
import discountsIcon from "../../assets/img/menu-icon-7.webp";
import popupCloseIcon from "../../assets/img/popup-close-icon.webp";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../common/Loader";
import HubspotsLogs from "../module-tabs-detail/HubspotLogs";
import { useQuery } from "react-query";

function ItemTab({ title, value }) {
    return (
        <div className="order-detail-box">
            <h6>{title}</h6>
            <h4>{value || '-'}</h4>
        </div>
    );
}

const AllDisablePickups = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [pickupdate, setpickupDate] = useState(null);

    const handleOpen = () => setIsOpen(true)

    const handleClose = () => setIsOpen(false)

    const fetchAllDisabledDates = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}disablepickup/all`);
        return response.data;
    }

    const {
        data, isLoading, refetch
    } = useQuery("fetchAllDisabledDates", fetchAllDisabledDates)

    const handleSubmit = async () => {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}disablepickup/create`, {
            date : pickupdate,
            is_active : true
        });

        if(response.status == 200){
            toast.success("Created Successfully!")
            handleClose()
            refetch()
        }
    }

    const handleDelete = async (id) => {
        const resp = await axios.delete(`${process.env.REACT_APP_API_URL}disablepickup/delete/${id}`)
        if (resp.status == 200) {
            toast.success("Deleted Successfully!")
            refetch()
        }
    }


    return (
        <>
            <div className="tab-detail-outer-box discounts-listing-box">
                <div className="head-box flex-box space-between align-center">
                    <h6 className="tab-detail-heading">
                        {" "}
                        <span>
                            <img src={discountsIcon} alt="" />
                        </span>{" "}
                        All Disabled Dates Overview
                    </h6>
                </div>
                <ul className="all-orders-listing">
                    <button type="button" onClick={handleOpen} className="save-info-btn" style={{ width: "20%" }}> + Create</button>
                </ul>
                {isOpen && (
                    <>
                        <div className="popup-outer-box">
                            <div className="popup-box user-edit-popup item-popup fixer-popup add-discount-popup">
                                <button className="popup-close-btn" onClick={handleClose}>
                                    <img src={popupCloseIcon} alt="" />
                                </button>
                                <div className="popup-edit-box">
                                    <h4 className="popup-heading">Please choose a date that you wants to disable for pickup!</h4>
                                    <input type="date" className="input" onChange={((e) => setpickupDate(e.target.value))} />
                                </div>
                                <div
                                    className="bottom-save-box flex-box space-between align-center"
                                    onClick={handleSubmit}
                                >
                                    <button className="save-info-btn">Save</button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <h6 className="tab-detail-heading">
                    All Disabled Dates
                </h6>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <ul className="all-orders-listing">
                            {data?.map((discounts, index) => (
                                <li key={index} >
                                    <div className="order-box flex-box align-center space-between">
                                        <ItemTab title={"Date"} value={discounts.date} />
                                        <ItemTab title={"Status"} value={"Active"} />
                                        <ItemTab title={"Action"} value={<button type="button" onClick={() => handleDelete(discounts?._id)} className="save-info-btn" >Delete</button>} />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </div>
        </>
    )
}

export default AllDisablePickups;