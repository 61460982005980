import * as FileSaver from "file-saver"
import XLSX from "sheetjs-style"
import csvIcon from "../../assets/img/export-csv-icon.webp"
const ExportExcel = ((exceldata,filename) => {
    const filetype = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const filextension = ".xlsx";
    const exportExcel = async () => {
        
        const excelDataFormatted = exceldata.exceldata.map((item) => {
            let percentage = 10.35;
        
            // Safely parse values and use 0 as a fallback if invalid
            let amount = parseFloat(item?.item?.amount) || 0;
            let totalDiscount = parseFloat(item?.item?.total_discount) || 0;
        
            // Calculate total after ensuring valid numbers
            let total = amount - totalDiscount;
        
            // Calculate totalTax only if total is a valid number
            let totalTax = (percentage / 100) * total;
        
            return {
                "Customer Name": item?.name,
                "Email": item?.email,
                "Phone": item?.phone,
                "Order Placed At": new Date(item?.createdAt).toLocaleDateString("en-US", "America/Los_Angeles"),
                "Order ID": item?.item?.custom_order_id,
                "Order Medium": item?.item?.order_type,
                "Order Amount": amount.toFixed(2),
                "10.35% inclusive tax (sales tax)": isNaN(totalTax) ? "0.00" : totalTax.toFixed(2), // Handle NaN cases
                "Platform Fee (pickup delivery)": item?.item?.order_type == "Online" ? 5 : 0,
                "Applied Discount": totalDiscount.toFixed(2),
                "Refund 1": item?.item?.refund_details && item?.item?.refund_details.length !== 0 ? 
                             parseFloat(item?.item?.refund_details[0].amount) || 0 : 0,
                "Pincode": item?.item?.shipping_address?.code || ""
            };
        });        

        console.log("exportExcel called", excelDataFormatted);
        // Check if exceldata is an array
        if (!Array.isArray(excelDataFormatted)) {
            console.error("exceldata is not an array");
            return;
        }
        const ws = XLSX.utils.json_to_sheet(excelDataFormatted);
        const wb = { Sheets : {'data' : ws}, SheetNames : ["data"] };
        const excelbuffer = XLSX.write(wb, { bookType : "xlsx", type : "array" })
        const data = new Blob([excelbuffer], {type : filetype});
        FileSaver.saveAs(data, "Orders Data"+filextension);
    }
    return (
        <>
        <h4 className='export-csv-btn' onClick={() => exportExcel(exceldata.filename)} >Export CSV <span><img src={csvIcon} alt="" /></span></h4>
        </>
    )
})

export default ExportExcel;