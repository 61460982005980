import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./assets/css/App.css";
import DashBoard from "./pages/DashBoard";
import { Toaster } from "react-hot-toast";
import CommonPage from "./pages/CommonPage";
import OrderDetail from "./pages/OrderDetail";
import CustomerDetail from "./pages/CustomerDetail";
import { QueryClient, QueryClientProvider } from "react-query";
import LogIn from "./pages/LogIn";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route path="/" element={<LogIn />} />
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/intake" element={<DashBoard />} />
          <Route path="/picked" element={<DashBoard />} />
          <Route path="/withfixer" element={<DashBoard />} />
          <Route path="/readyfordelivery" element={<DashBoard />} />
          <Route path="/fixedbutwithfixer" element={<DashBoard />} />
          <Route path="/fixedandwithfxry" element={<DashBoard />} />
          <Route path="/delivered" element={<DashBoard />} />
          <Route path="/archived" element={<DashBoard />} />
          <Route path="/onhold" element={<DashBoard />} />
          <Route exact path="/:status/:orderId" element={<OrderDetail />} />
          <Route path="/customers" element={<CommonPage />} />
          <Route
            exact
            path="/customers/:customerName"
            element={<CustomerDetail />}
          />
          <Route path="/disabledpickups" element={<CommonPage />} />
          <Route path="/items" element={<CommonPage />} />
          <Route path="/services" element={<CommonPage />} />
          <Route path="/fixers" element={<CommonPage />} />
          <Route path="/paid" element={<CommonPage />} />
          <Route path="/pending" element={<CommonPage />} />
          <Route path="/dispute" element={<CommonPage />} />
          <Route path="/discounts" element={<CommonPage />} />
          <Route path="/zipcodes" element={<CommonPage />} />
          <Route path="/subscribers" element={<CommonPage />} />
          <Route path="/overview" element={<CommonPage />} />
          <Route path="/hubspotsoverview" element={<CommonPage />} />
        </Routes>
      </Router>
      <Toaster />
    </QueryClientProvider>
  );
}

export default App;
